<template>
  <div>
    <vs-row
      :class="showSkuTable ? 'flex flex-wrap gap-2' : ''"
      class="justify-between"
    >
      <vs-col :vs-w="showSkuTable ? '5' : '12'">
        <p class="text-xl text-bold">List Item Units</p>
        <hr />
        <div class="flex">
          <vs-button
            color="primary"
            type="filled"
            size="small"
            class="my-4"
            icon="search"
            v-show="showBtnSearch"
            @click="toggleShowSkuTable"
            >Search</vs-button
          >
          <!-- <vs-button
            color="danger"
            type="filled"
            size="small"
            class="my-4"
            icon="restore"
            v-show="!showBtnSearch"
            @click="clickRestore"
          /> -->
        </div>

        <!-- table selected line item -->
        <vs-table stripe :data="selectedItemUnits">
          <template slot="thead">
            <vs-th> # </vs-th>
            <vs-th> SKU Code </vs-th>
            <vs-th> SKU Name </vs-th>
            <vs-th> HU </vs-th>
            <vs-th v-if="form.so_types == 'with_so_reference'">
              Qty Invoice
            </vs-th>
            <vs-th> Qty Return </vs-th>
            <vs-th>Reason</vs-th>
            <vs-th>Attachments </vs-th>
            <vs-th v-show="form.so_types == 'without_so_reference'" style="width: 100px;">PPN Type </vs-th>
            <vs-th>Price</vs-th>
            <vs-th v-if="form.so_types == 'without_so_reference'"
              >Adj. Price</vs-th
            >
            <!-- <vs-th>Sub Total</vs-th> -->
            <vs-th>Reg. Discount</vs-th>
            <vs-th>Tax</vs-th>
            <vs-th>Grand Total </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <vs-button
                  v-show="showDeleteOnly(tr)"
                  color="danger"
                  type="filled"
                  size="small"
                  icon="delete"
                  @click="handleDelete(tr)"
                />
              </vs-td>
              <vs-td>
                <span v-if="tr.parent">{{ tr.sku_code }}</span>
              </vs-td>
              <vs-td>
                <span v-if="tr.parent">{{ tr.item_name }}</span>
                <strong v-if="tr.isFreeGood" class="text-success">
                  {{ tr.item_name }}
                </strong>
              </vs-td>
              <vs-td>
                {{ tr.unit_name }}
              </vs-td>
              <vs-td v-if="form.so_types == 'with_so_reference'">
                {{ tr.qty_invoice }}
              </vs-td>
              <vs-td>
                <NumberInput
                  v-model="tr.quantity"
                  :min="0"
                  :disabled="disabledInput"
                />
              </vs-td>
              <vs-td>
                <multiselect
                  v-show="showDelete(tr) || (tr.parent)"
                  :disabled="disabledInput"
                  v-model="tr.reason"
                  :options="optionsReason"
                  :max-height="125"
                  :allow-empty="true"
                  :select-label="''"
                  deselect-label=""
                  :searchable="true"
                  :custom-label="customLabelReason"
                >
                  <span slot="noResult">Oops! No data found</span>
                </multiselect>
              </vs-td>
              <vs-td>
                <InputFile
                  v-show="(showDelete(tr) || (tr.parent)) && !disabledInput"
                  :isDisabled="disabledInput"
                  :acceptedTypes="acceptedTypes"
                  @data="setAttachment($event, tr.sku_code, tr.unit_name)"
                  setPathUpload="sales-return-v2"
                  :isWithAPI="true"
                />
                <!-- list -->
                <ul class="mt-2" v-if="tr.parent && tr.attachments!=null">
                  <li
                    v-for="(attachment, index) in tr.attachments"
                    :key="index"
                    class="mb-2"
                  >
                    <div class="flex gap-2">
                      <a
                        :href="attachment"
                        target="_blank"
                        class="text-blue-500"
                        >File {{ index + 1 }}</a
                      >
                      <!-- delete btn -->
                      <vs-button
                        color="danger"
                        type="filled"
                        size="small"
                        icon="delete"
                        v-if="!disabledInput"
                        @click="handleDeleteAttachment(tr, index)"
                      />
                    </div>
                  </li>
                </ul>
                <span v-if="tr.parent && tr.attachments==null">No Attachment.</span>
              </vs-td>
              <vs-td v-show="form.so_types == 'without_so_reference'">
                <span>{{ (tr.taxName != undefined && tr.taxName != null) ? tr.taxName : "-" }}</span>
              </vs-td>
              <vs-td>
                <span>{{ formatCurrencyIDR(tr.price) }}</span>
              </vs-td>
              <!-- <vs-td>
                <span>{{ formatCurrencyIDR(tr.subtotal) }}</span>
              </vs-td> -->
              <vs-td v-show="form.so_types == 'without_so_reference'">
                <NumberInput
                  v-model="tr.adj_price"
                  :min="0"
                  :version="'v2'"
                  :disabled="disabledInput"
                />
              </vs-td>
              <vs-td>
                {{ formatCurrencyIDR(tr.discount) }}
              </vs-td>
              <vs-td>
                {{ formatCurrencyIDR(tr.taxAmount) }}
              </vs-td>

              <vs-td v-if="form.so_types == 'without_so_reference'">
                <!-- {{ formatCurrencyIDR( parseFloat(tr.price) * parseFloat(tr.quantity) + parseFloat(tr.taxAmount) - parseFloat(tr.discount) ) }} -->
                {{ tr.quantity > 0 ? formatCurrencyIDR( parseFloat(tr.adj_price) * parseFloat(tr.quantity) + parseFloat(tr.taxAmount) - parseFloat(tr.discount) ) : formatCurrencyIDR( parseFloat("0") ) }}
              </vs-td>
              <vs-td v-else-if="form.so_types == 'with_so_reference' && tr.isFreeGood">
                {{ formatCurrencyIDR( parseFloat("0") ) }}
              </vs-td>
              <vs-td v-else>
                {{ tr.quantity > 0 ? formatCurrencyIDR( parseFloat(tr.price) * parseFloat(tr.quantity) + parseFloat(tr.taxAmount) - parseFloat(tr.discount) ) : formatCurrencyIDR( parseFloat("0") ) }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <!-- return summary -->
        <vs-row class="flex my-2">
          <vs-col vs-w="4" vs-offset="8">
            <p class="text-xl">Return Summary</p>
            <hr />

            <div class="my-2 text-right">
              <label>Total List Price</label>
              <br />
              <h4 class="font-bold">
                <!-- {{ formatCurrencyIDR( parseFloat(form.summary_data.total_list_price) ) }} -->
                {{ formatCurrencyIDR( parseFloat(this.total_line.list_price) ) }}
              </h4>
            </div>

            <div class="my-2 text-right">
              <label>Total Adj. Price</label>
              <br />
              <h4 class="font-bold">
                <!-- {{ formatCurrencyIDR( parseFloat(form.summary_data.total_adj_price) ) }} -->
                {{ formatCurrencyIDR( parseFloat(this.total_line.adj_price) ) }}
              </h4>
            </div>
            <!-- <div class="my-2">
              <label>Total Net</label>
              <br />
              <h4 class="font-bold">
                {{ formatCurrencyIDR(parseFloat(form.summary_data.total_net)) }}
              </h4>
            </div> -->
            <div class="my-2 text-right">
              <label>Total Tax</label>
              <br />
              <h4 class="font-bold">
                {{ formatCurrencyIDR( parseFloat(this.total_line.tax) ) }}
              </h4>
            </div>

            <!--  -->

            <div v-if="form.so_types == 'with_so_reference'" class="my-2 text-right">
              <label>Total Discount</label>
              <br />
              <h4 class="font-bold">
                {{ formatCurrencyIDR( parseFloat(this.total_line.discount) ) }}
              </h4>
            </div>

            <!--  -->
            <div class="my-2 text-right">
              <label>Total Sales Return</label>
              <br />
              <h4 class="font-bold">
                <!-- {{ form.so_types == 'without_so_reference' ?
                  formatCurrencyIDR( parseFloat(form.summary_data.total_adj_price + form.summary_data.total_tax - form.summary_data.total_discount) ) 
                  :
                  formatCurrencyIDR( parseFloat(form.summary_data.total_list_price + form.summary_data.total_tax - form.summary_data.total_discount) )
                }} -->
                {{ formatCurrencyIDR( parseFloat(this.total_line.sales_return) ) }}
              </h4>
            </div>
            <!-- <div class="my-2">
              <label>Total Charges</label>
              <br />
              <h4 class="font-bold">
                {{
                  formatCurrencyIDR(parseFloat(form.summary_data.total_charges))
                }}
              </h4>
            </div>
            <div class="my-2">
              <label>Total Return</label>
              <br />
              <h4 class="font-bold">
                {{
                  formatCurrencyIDR(parseFloat(form.summary_data.total_return))
                }}
              </h4>
            </div> -->
          </vs-col>
        </vs-row>
      </vs-col>

      <!-- table choose item units -->
      <transition name="detail-fade">
        <vs-col v-if="showSkuTable" vs-w="6">
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <!-- <TableItemUnit
            @data="setItemUnit"
            ref="tableItemUnit"
            :itemUnitIDs="form.customer_invoice_data.item_unit_ids"
          /> -->
          <TableItem
            @data="setItem"
            ref="tableItem"
            :itemUnitIDs="form.customer_invoice_data.item_unit_ids"
          />
        </vs-col>
      </transition>
    </vs-row>
  </div>
</template>

<script>
import TableItemUnit from "@/components/master/TableItemUnit.vue";
import { formatCurrencyIDR } from "@/helpers/common.js";
import InputFile from "@/components/master/InputFile.vue";
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import SelectMasterReasonV2 from "@/components/master/SelectMasterReasonV2.vue";
import TableItem from "@/components/master/TableItem.vue";
import VueNumericInput from "vue-numeric-input";
import NumberInput from "@/components/master/NumberInput.vue";

const SalesReturnV2Repo = RepositoryFactory.get("salesReturnV2");
const masterRepository = RepositoryFactory.get("master");

export default {
  components: {
    TableItemUnit,
    InputFile,
    SelectMasterReasonV2,
    VueNumericInput,
    TableItem,
    NumberInput,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    showBtnSearch: {
      type: Boolean,
      default: false,
    },
    isWithSoReference: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabledInput: false,
      optionsReason: [],
      selectedReason: null,
      showSkuTable: false,
      selectedItemUnits: [],
      // jpg, jpeg, png, pdf, doc, docx, xls, xlsx
      acceptedTypes: [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      total_line: {
        list_price : 0,
        adj_price : 0,
        tax : 0,
        sales_return: 0,
        discount: 0,
      },
			params : {
				reason_group : 'Customer Return',
			},
    };
  },
  mounted() {
    this.getReason();
    if (this.form.isEdit && this.form.so_types == 'without_so_reference') {
      this.showBtnSearch = true;
    } else if (this.form.isEdit && this.form.so_types == 'with_so_reference'){
      this.showBtnSearch = false;
    }
    if (this.form.isDetail == 1) {
      this.showBtnSearch = false;
      this.disabledInput = true;
    }
    this.setSelectedItemUnits();
    this.calculateSummary(this.selectedItemUnits);
  },
  methods: {
    handleDeleteAttachment(tr, index) {
      let copierSelectedItemUnits = JSON.parse(
        JSON.stringify(this.selectedItemUnits)
      );

      for (let i = 0; i < copierSelectedItemUnits.length; i++) {
        if (copierSelectedItemUnits[i].sku_code == tr.sku_code) {
          let getIndex = 0;
          for (
            let j = 0;
            j < copierSelectedItemUnits[i].attachments.length;
            j++
          ) {
            if (j == index) {
              getIndex = j;
              break;
            }
          }

          // remove
          copierSelectedItemUnits[i].attachments.splice(getIndex, 1);
        }
      }
      this.form.return_line_item_data = copierSelectedItemUnits;
      this.selectedItemUnits = copierSelectedItemUnits;
      this.$forceUpdate();
    },
    showDeleteOnly(tr) {
      if ((this.form.isEdit && this.form.so_types != 'without_so_reference') || this.form.isDetail == 1) {
        return false;
      }

      if (tr.parent && this.form.so_types == 'without_so_reference') {
        if (this.form.isDetail) {
          return false;
        }
        return true;
      }
    },
    showDelete(tr) {
      if (tr.parent && this.form.so_types == 'without_so_reference') {
        if (this.form.isDetail) {
          return false;
        }
        return true;
      }
    },
    handleDelete(tr) {
      // delete this.selectedItemUnits by sku code same
      this.selectedItemUnits = this.selectedItemUnits.filter(
        (item) => item.sku_code != tr.sku_code
      );
    },
    handleClose() {
      this.showSkuTable = false;
    },
    async getReason() {
      console.log("this.params", this.params);
      try {
        const resp = await masterRepository.getMasterReason({
          params: this.params,
        });
        if (resp.code == 200) {
          this.optionsReason = [];
          for (let i = 0; i < resp.data.records.length; i++) {
            this.optionsReason.push(resp.data.records[i].reason);
          }
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching master reason",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching master reason",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
    setSelectMasterReason(data, index) {
      this.selectedItemUnits[index].reason = data.reason;
      this.form.return_line_item_data = this.selectedItemUnits;
    },
    convertDTO(item) {
      let taxAmt = this.form.so_types == 'without_so_reference' ? Math.floor((item.price * item.quantity) * item.tax_rate / 100) : item.TaxAmount;
      let isFg = ((item.Subtotal == item.Discount) && item.Qty > 0) ? true : false;
      return {
        id: 0,
        parent: false,
        invoice_line_id: item.ID,
        sku_code: item.SkuCode,
        item_name: item.ItemName,
        unit_name: item.Unit,
        qty_invoice: parseFloat(item.Qty),
        quantity: 0,
        so_price: parseFloat(item.Price),
        price: parseFloat(item.Price),
        subtotal: parseFloat(item.Subtotal),
        // adj price is selisih antara (so_price - discount) dengan price
        // adj_price: 0,
        adj_price: this.form.so_types == 'without_so_reference' ? item.Price : 0, // adj_price didefault dengan price, tapi editable
        taxAmount: !isFg ? taxAmt : 0,
        discount: item.Discount,
        reason: "",
        attachments: [],
        tax_rate: item.tax_rate != undefined && !isFg ? item.tax_rate : 0,
        taxRate: item.tax_rate != undefined && !isFg ? item.tax_rate : 0,
        taxName: (this.form.so_types == 'without_so_reference' && item.tax_name != undefined) && !isFg ? item.tax_name : null,
        isFreeGood: isFg,
      };
    },
    async setAttachment(data, skuCode, unitName) {
      // update attachment to each sku code
      for (let i = 0; i < this.selectedItemUnits.length; i++) {
        if (this.selectedItemUnits[i].sku_code == skuCode) {
          this.selectedItemUnits[i].attachments = data;
        }
      }
      this.form.return_line_item_data = this.selectedItemUnits;
      // force update selected item units
      this.$forceUpdate();
    },
    async setSelectedItemUnits() {
      // console.log("this.form", this.form);
      // console.log("this.form.isEdit", this.form.isEdit);
      // console.log("this.form.isDetail", this.form.isDetail);
      if (this.form.isEdit || this.form.isDetail == 1) {
        // console.log(495)
        this.selectedItemUnits = [];
        this.form.return_line_item_data.map((item) => {
          console.log("item", item)
          let data = {
            parent: item.parent,
            invoice_line_id: item.invoice_line_id,
            sales_return_line_id: item.sales_return_line_id,
            sku_code: item.item_sku_code,
            item_name: item.item_name,
            unit_name: item.item_unit,
            quantity: parseFloat(item.quantity),
            qty_invoice: parseFloat(item.qty_invoice),
            so_price: item.price,
            price: parseFloat(item.price),
            taxAmount: this.form.so_types == 'without_so_reference' ? Math.floor((item.price * item.quantity) * item.tax_rate / 100) : item.tax,
            taxRate: item.tax_rate,
            taxName: this.form.so_types == 'without_so_reference' ? item.tax_name : null,
            discount: item.reg_discount,
            adj_price: parseFloat(item.adj_price),
            reason: item.reason,
            attachments: item.attachments,
          };
          this.selectedItemUnits.push(data);
        });
      } else {
        console.log(520)
        if (
          this.form.customer_invoice_data.invoice_code != null ||
          this.form.customer_invoice_data.invoice_code != ""
        ) {
        console.log(525)
        const temps = this.form.customer_invoice_data.sales_order_lines_temp;
          this.selectedItemUnits = [];
          await temps.forEach((item) => {
            let newDataDto = this.convertDTO(item);
            this.selectedItemUnits.push(newDataDto);
          });
        }
      }
    },
    formatCurrencyIDR,
    setItem(data) {
      if (this.addValidation(data)) {
        this.$vs.loading();
        // get item hu
        SalesReturnV2Repo.getItemHU({
          sku_code: data.SkuCode,
        }).then((response) => {
          if (response.code == 200) {
            let responseUnits = response.data;

            responseUnits.forEach((item, index) => {
              // get price each item unit id
              const date = new Date().toISOString().split("T")[0];
              const warehouse_id =
                this.form.customer_return_data.territory_warehouse.warehouse_id;
              const sku_code = data.SkuCode;
              const item_unit_id = item.item_unit_id;
              const tax_rate = item.tax_rate;
              const tax_name = item.tax_name;
              const territory_id =
                this.form.customer_return_data.territory_warehouse.territory_id;
              const pricing_group_id = this.form.customer_data.pricing_group_id;
              const customer_group_id1 =
                this.form.customer_data.customer_group_id_1;
              const customer_group_id2 =
                this.form.customer_data.customer_group_id_2;
              const customer_group_id3 =
                this.form.customer_data.customer_group_id_3;
              const customer_id = this.form.customer_data.customer_id;
              const is_simulation = false;

              SalesReturnV2Repo.getPriceItem({
                date: date,
                warehouse_id: warehouse_id,
                sku_code: sku_code,
                item_unit_id: item_unit_id,
                territory_id: territory_id,
                pricing_group_id: pricing_group_id,
                cg1: customer_group_id1,
                cg2: customer_group_id2,
                cg3: customer_group_id3,
                customer_id: customer_id,
                is_simulation: is_simulation,
              }).then((responsePriceItem) => {
                let price = 0;
                if (responsePriceItem.code == 200) {
                  price = responsePriceItem.data.price;
                }

                const newItem = {
                  id: item.item_unit_id,
                  parent: false,
                  invoice_line_id: 0,
                  sales_order_line_id: 0,
                  sku_code: data.SkuCode,
                  item_name: data.Name,
                  unit_name: item.unit_name,
                  quantity: 0,
                  so_price: price,
                  price: price,
                  // adj_price: 0,
                  adj_price: this.form.so_types == 'without_so_reference' ? price : 0, // adj_price utk withoutRef didefault dengan price, tapi editable
                  taxAmount: 0,
                  taxRate: tax_rate,
                  taxName: tax_name,
                  discount: 0,
                  reason: "",
                  attachments: [],
                };

                if (index == 0) {
                  newItem.parent = true;
                }

                this.selectedItemUnits.push(newItem);
                this.form.return_line_item_data = this.selectedItemUnits;

                this.$vs.loading.close();
              });
            });
          }
        });
      }
    },
    addValidation(data) {
      // if sku_code and  unit_name is exist
      if (
        this.selectedItemUnits.some((item) => item.sku_code == data.SkuCode)
      ) {
        this.$vs.notify({
          title: "Error",
          text: "SKU Code and Unit Name is exist",
          color: "danger",
        });
        return false;
      }

      return true;
    },
    async calculateSummary(data) {
      // calculate adj price each row
      console.log(data)
      await data.forEach((item) => {
        if (this.form.so_types == "with_so_reference") {
          item.adj_price = 0;
          return;
        } else {
          item.price = parseFloat(item.price);
        }
      });

      // total list price
      const totalListPrice = data.reduce((acc, item) => {
        // return (
        //   acc + (item.price * item.quantity - item.adj_price * item.quantity)
        // );
				return acc + item.price * item.quantity;
      }, 0);

      // total adj price
      const totalAdjPrice = data.reduce((acc, item) => {
        // return (
        //   acc + (item.price * item.quantity - item.adj_price * item.quantity)
        // );
				return acc + item.adj_price * item.quantity;
      }, 0);

      // total discount
      const totalDiscount = data.reduce((acc, item) => {
        if (this.form.so_types == 'with_so_reference'){
          if (item.quantity > 0){
            return acc + item.discount;
          } else {
            return acc
          }
        } else {
          return acc + (item.discount * item.quantity);
        }
      }, 0);

      if (this.form.so_types == 'with_so_reference'){
        if(this.form.customer_return_data.tax_id != null) {
          data.forEach((item) => {
            if (!item.isFreeGood) {
              item.tax_rate = this.form.customer_return_data.tax_id.Rate;
              item.taxAmount = item.quantity > 0 ? Math.floor(((item.price * item.quantity) - item.discount) * this.form.customer_return_data.tax_id.Rate / 100) : 0;
            }
          })
        }
      } else {
        // data.forEach((item) => {
        //   if(item.tax_rate != undefined && item.tax_rate > 0) {
        //       item.taxAmount = (item.adj_price * item.quantity) * item.tax_rate / 100
        //   }
        // })
        this.selectedItemUnits.forEach((item, i) => {
          console.log(i, item)
          console.log(this.selectedItemUnits[i])
          if(item.tax_rate != undefined && item.tax_rate > 0 && !item.isFreeGood) {
              this.selectedItemUnits[i].taxAmount = Math.floor(((item.adj_price * item.quantity) - item.discount) * item.tax_rate / 100)
          }
          if(item.taxRate != undefined && item.taxRate > 0 && !item.isFreeGood) {
              this.selectedItemUnits[i].taxAmount = Math.floor(((item.adj_price * item.quantity) - item.discount) * item.taxRate / 100)
          }
        })
      }

      // total tax
      const totalTax = data.reduce((acc, item) => {
        console.log(item);
        return acc + item.taxAmount;
      }, 0);

      // TODO: total net

      // TODO: total charges

      // TODO: total return
      console.log("totalListPrice", totalListPrice);
      console.log("totalAdjPrice", totalAdjPrice);
      console.log("totalDiscount", totalDiscount);
      console.log("totalTax", totalTax);

      this.form.summary_data.total_list_price = totalListPrice;
      this.form.summary_data.total_adj_price = totalAdjPrice;
      this.form.summary_data.total_discount = totalDiscount;
      this.form.summary_data.total_tax = totalTax;

      this.total_line = {
        list_price : totalListPrice,
        adj_price : totalAdjPrice,
        tax : totalTax,
        sales_return: this.form.so_types == "with_so_reference" ? (totalListPrice + totalTax) - totalDiscount : totalAdjPrice + totalTax,
        discount: totalDiscount,
      },
      console.log("this.total_line", this.total_line);
      console.log("this.form", this.form);
      console.log("this.form.summary_data.total_list_price", this.form.summary_data.total_list_price);
      console.log("this.form.summary_data.total_adj_price", this.form.summary_data.total_adj_price);
      console.log("this.form.summary_data.total_tax", this.form.summary_data.total_tax);
    },
    customLabelReason(option) {
      return `${option}`;
    },
    toggleShowSkuTable(){
      console.log("toggleShowSkuTable")
      console.log("this.form", this.form)
      if(this.form.so_types == "without_so_reference") {
        if(this.form.customer_data.pickup_from_ship_to == null) {
          this.$vs.notify({
            title: "Warning",
            text: "Pickup From / Customer Ship To is required",
            color: "warning",
          });
          return true;
        } else {
          this.showSkuTable = !this.showSkuTable
        }
      }
    },
  },
  watch: {
    "form.customer_return_data.tax_id.Rate": {
      handler: function (val) {
        this.calculateSummary(this.selectedItemUnits);
      },
      deep: true,
    },
    selectedItemUnits: {
      handler: function (val) {
        this.form.return_line_item_data = this.selectedItemUnits;
        // calculate summary
        this.calculateSummary(val);
      },
      deep: true,
    },
    "form.customer_invoice_data.invoice_lines": {
      handler: function (val) {
        if (val != null) {
          this.selectedItemUnits = [];
          let allDtos = [];
          val.forEach((item) => {
            let newDataDto = this.convertDTO(item);
            allDtos.push(newDataDto);
          });
          // group by sku code
          let groupBySkuCode = allDtos.reduce((acc, item) => {
            if (!acc[item.sku_code]) {
              acc[item.sku_code] = [];
            }
            acc[item.sku_code].push(item);
            return acc;
          }, {});

          // make first line on each sku code as parent
          groupBySkuCode = Object.values(groupBySkuCode).map((item) => {
            item[0].parent = true;
            return item;
          });

          // add unit names on item.parent each sku code
          groupBySkuCode.forEach((item) => {
            let unit_names = item.map((item) => item.unit_name);
            item.forEach((item) => {
              if (item.parent) {
                item.unit_names = unit_names.join(", ");
              }
            });
          });

          // push to selected item units
          groupBySkuCode.forEach((item) => {
            item.forEach((item) => {
              this.selectedItemUnits.push(item);
            });
          });
          this.form.return_line_item_data = this.selectedItemUnits;
        }
      },
      deep: true,
    },
    // "form.customer_invoice_data.sales_order_lines": {
    //   handler: function (val) {
    //     if (val != null) {
    //       this.selectedItemUnits = [];
    //       val.forEach((item) => {
    //         let newDataDto = this.convertDTO(item);
    //         this.selectedItemUnits.push(newDataDto);
    //       });
    //       this.form.return_line_item_data = this.selectedItemUnits;
    //     }
    //   },
    //   deep: true,
    // },
    // "form.isEdit": {
    //   handler: function (val) {
    //     if (val) {
    //       this.setSelectedItemUnits();
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>
