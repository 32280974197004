<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th width="5%">Action</vs-th>
        <vs-th>Customer Return Data</vs-th>
        <vs-th>Customer Data</vs-th>
        <vs-th>Invoice Data</vs-th>
        <vs-th>Territory Warehouse</vs-th>
        <vs-th>Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="flex gap-2">
              <vs-button
                color="success"
                type="filled"
                size="small"
                icon-pack="feather"
                icon="icon-eye"
                @click="clickDetail_(tr.id, tr)"
              />
              <vs-button
                color="success"
                type="filled"
                size="small"
                icon="description"
                @click="clickExport(tr.id, 'excel')"
              >
                Excel
              </vs-button>
              <vs-button
                color="primary"
                type="filled"
                size="small"
                icon="picture_as_pdf"
                @click="clickExport(tr.id, 'pdf')"
              >
                PDF
              </vs-button>
            </div>
          </vs-td>
          <vs-td>
            <p><b>Customer Return Code: </b> {{ tr.customer_return_code }}</p>
            <p><b>Reference Code: </b> {{ tr.reference_code }}</p>
            <p><b>External Code: </b> {{ tr.external_code }}</p>
            <p>
              <b>Date: </b>
              {{ dateFormat(tr.customer_return_date, "DD-MM-YYYY") }}
            </p>
            <p>
              <b>Created At: </b>
              {{ dateFormat(tr.creation_date, "DD-MM-YYYY") }}
            </p>
            <p><b>Created By: </b> {{ tr.created_by.Name }}</p>
            <p><b>Salesman: </b> {{ tr.sales_personal_id != 0 ? tr.salesman.CodeExternal + " | " + tr.salesman.Name : " - " }}</p>

            <p><b>Reason: </b> {{ tr.reason }}</p>

            <p><b>Customer Return Value : </b> {{ formatCurrencyIDR(tr.value) }}</p>

            <p><b>Customer Return Type: </b> {{ tr.customer_return_type }}</p>

            <p><b>Territory: </b> {{ tr.territory != "" ? tr.territory : ((tr.territory_code != "" || tr.territory_name != "") ? tr.territory_code + " - " + tr.territory_name : "") }}</p>
            <p><b>Note: </b> {{ tr.note }}</p>
          </vs-td>
          <vs-td>
            <p><b>Customer Code: </b> {{ tr.customer_code }}</p>

            <p><b>Customer Name: </b> {{ tr.customer_name }}</p>

            <p><b>ShipTo: </b> {{ tr.ship_to_id == 0 ? tr.ship_to : (tr.ship_to_code + " - " + tr.ship_to_name + " - " + tr.ship_to_address) }}</p>
          </vs-td>
          <vs-td>
            <p><b>Invoice Code: </b> {{ tr.invoice_code }}</p>

            <p>
              <b>Invoice Date: </b>
              {{ dateFormat(tr.invoice_date, "DD-MM-YYYY") }}
            </p>
          </vs-td>
          <vs-td>
            <p>
              <b>Warehouse Code - Nama : </b> {{ tr.warehouse_code }}
              {{ tr.warehouse_name }}
            </p>

            <p>
              <b>Territory Code - Name: </b> {{ tr.territory_code }} -
              {{ tr.territory_name }}
            </p>
          </vs-td>
          <vs-td>
            <b>{{ getStatusSR(tr) }}</b>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const SalesReturnV2Repo = RepositoryFactory.get("salesReturnV2");
import { dateFormat, formatCurrencyIDR } from "@/helpers/common";

export default {
  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      baseUrl: "",
      table: this.tableDefaultState(),
      dataDel: null,
    };
  },
  methods: {
    formatCurrencyIDR,
    clickExport(id, type) {
      if (type == "excel") {
        this.$vs.loading();
        this.$http
          .get(`api/v2/sales-return-management/${id}/export/${type}`, {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/octet-stream",
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            var fileTitle = "SR" + id + "-" + new Date().getTime();
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileTitle + ".xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          });

        let ids = [];
        ids.push(id);
        if (ids.length == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Please select at least one collection plan",
            position: "right-top",
          });
          return;
        }
      } else if (type == "pdf") {
        // open new tab with route
        // const routeData = this.$router.resolve({
        //   name: "sales-return-v2-pdf",
        //   path: "/customer-return/sales-return-v2/pdf",
        //   query: { id: id },
        // });
        // window.open(routeData.href, "_blank");

        // printOpenTab(tr, isBillingList = false) {
          if (id) {
            sessionStorage.setItem("sr_id", JSON.stringify([id]));
            sessionStorage.setItem("param_type", "id");
          }

          window.open("/customer-return/sales-return-v2/pdf", "_blank");
        // },
      }
    },
    async clickEdit(id) {
      this.$router.push({
        name: "sales-return-v2-form",
        path: "/customer-return/sales-return-v2/form",
        query: {
          id: id,
        },
      });
    },
    async clickEdit_(idSR, dataSR) {
      this.$emit("clickEdit_", idSR, dataSR, "new_tab");
    },
    async clickDetail_(idSR, dataSR) {
      this.$emit("clickEdit_", idSR, dataSR, "");
    },
    clickDelete(dt) {
      this.dataDel = dt;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure delete this "+dt.customer_return_code+" data from draft ?",
        accept: this.confirmDelete,
        cancel: () => {
          this.dataDel = null
        },
      });
    },
    clickCancel(dt) {
      this.dataDel = dt;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to cancel this "+dt.customer_return_code+" data ?",
        accept: this.handleCancel,
        cancel: () => {
          this.dataDel = null
        },
        parameters: [dt.id],
      });
    },
    async handleCancel(id) {
      this.$vs.loading();
      try {
        const resp = await SalesReturnV2Repo.doApprovalStatus(id, 11, "");
        if (resp.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: resp.message,
            color: "success",
          });
          await this.getData();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
        });
      }
      this.$vs.loading.close();
    },
    dateFormat,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        status: null,
        territory_ids:[],
      };
    },
    async handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      await this.getData();
    },
    async handleChangePage(page) {
      this.table.page = page;
      await this.getData();
    },
    async handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      await this.getData();
    },
    async handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      await this.getData();
    },
    async handleClose() {
      await this.getData();
      this.detail = false;
    },
    async getData() {
      await this.$vs.loading();
      if (this.$userLogin.role[0].Group == "Sales") {
        this.table.territory_ids = [this.$userLogin.territory_id];
      }
      console.log("this.table", this.table);

      try {
        const params = {
          ...this.table,
          ...this.filter,
        };
        // remove data
        delete params.data;
        const resp = await SalesReturnV2Repo.getList({
          params: params,
        });
        if (resp.code == 200) {
          this.table.data = resp.data.records;
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          await this.setStartEnd();
          await this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
          await this.$vs.loading.close();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
        });
        await this.$vs.loading.close();
      }
    },
    async setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    confirmDelete() {
      // Belum ready, delete dialihkan ke cancel.
      console.log("this.dataDel");
      console.log("this.dataDel", this.dataDel);
      this.$vs.loading();
      try {
        const resp = SalesReturnV2Repo.softDelete(this.dataDel.id);
        if (resp.code == 200) {
          this.getData();
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
          });
          this.$vs.loading.close();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
    getStatusSR(sr){
      // From DB
      // 0=Open,1=Approval,2=Release,3=Released,4=Received,5=PartialReceiveOpen,6=Reject,7=Complete,8=wait approval reversal,9=reversed
      console.log(sr)
      if (sr.status == 0) {
        return "Draft"
      }
      if (sr.status == 12) {
        return "Inquiry"
      }
      if (sr.status == 1) {
        return "Waiting"
      }
      if (sr.status == 3 && (sr.status_gir != "Completed" || sr.status_gir != "completed")) {
        return "Released"
      }
      if (sr.status == 7) {
        return "Closed"
      }
      if (sr.status == 6) {
        return "Rejected"
      }
      if (sr.status == 11) {
        return "Cancelled"
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
